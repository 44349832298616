<template>

	<div>
		<div class="header filter">
			<div class="top top_mafi"><!--trans-->
				<div class="util shadow">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<div class="save">
					<button
						@click="postCartel"
						class="btn_save"
					>다음</button>
				</div>
			</div>
		</div>
		<div class="section_wrap pt-50">
			<!-- 커버등록 -->
			<div class="cartel_cover">
				<template
					v-if="item_cartel.step == 1"
				>
					<label for="albumFile" class="cover_albumfile" aria-label="사진올리기"></label>
					<input type="file" id="albumFile" class="fileform_hidden" accept="image/*" @change="setFile" ref="file">
					<div
						v-if="item_cartel.img_src"
						class="cover_background"
					>
						<img
							:src="item_cartel.img_src"
							style="width: 100%"
						/>
					</div>
				</template>
				<div
					v-else
					class="cover_background"
				>
					<div class="title_area">
						<div class="title_area_container">
							<h2>{{ item_cartel.cartl_name }}</h2>
						</div>
					</div>
					<img
						v-if="item_cartel.img_src"
						:src="item_cartel.img_src"
						style="width: 100%"
					/>
				</div>
			</div>
			<template
				v-if="item_cartel.step == 1"
			>
			<div class="container">
				<div class="subindex_mainbox">
					<!-- 카르텔이름 -->
					<div class="input_area">
						<div class="bright_input">
							<label for="cartelName">카르텔 이름</label>
							<div class="input_utill">
								<input
									v-model="item_cartel.cartl_name"
									type="text" id="cartelName" placeholder="카르텔 이름을 입력하세요." maxlength="20"
								>
								<div class="input_utill_area">
									<div class="btn_del">
										<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
										<button
											v-if="item_cartel.cartl_name"
											@click="item_cartel.cartl_name = ''"
											class="active"
										><span class="hide">닫기</span></button>
									</div>
									<!-- TODO : 텍스트 수 카운트 필요 -->
									<span class="input_text_count">{{ item_cartel.cartl_name.length }}/20</span>
								</div>
							</div>
						</div>
					</div>
					<!-- //카르텔이름 -->
					<!-- 카르텔설명 -->
					<div class="input_area">
						<div class="bright_input">
							<label for="cartelDesc">카르텔설명</label>
							<div class="input_utill">
								<input
									v-model="item_cartel.cartl_desctn"
									type="text" id="cartelDesc" placeholder="카르텔에 대한 설명을 해주세요." maxlength="40"
								>
								<div class="input_utill_area">
									<div class="btn_del">
										<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
										<button
											v-if="item_cartel.cartl_desctn"
											class="active"
											@click="item_cartel.cartl_desctn = ''"
										><span class="hide">닫기</span></button>
									</div>
									<!-- TODO : 텍스트 수 카운트 필요 -->
									<span class="input_text_count">{{ item_cartel.cartl_desctn.length }}/40</span>
								</div>
							</div>
						</div>
					</div>
					<!-- //카르텔설명 -->
					<!-- 가입승인 -->
					<div class="input_area">
						<div class="flex align_items_center justify_content_between">
							<div class="tit mb-0">자동 가입 승인</div>
							<p class="toggle-group h_unset" @click="item_cartel.entry_approval_fg = !item_cartel.entry_approval_fg">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<span class="btn-toggle " :class="{ on: item_cartel.entry_approval_fg}"><em class="hide">가입 승인</em></span>
							</p>
						</div>
						<p class="input_desc">
							보스, 또는 언더보스의 승인을 통해서만 카르텔 가입이 가능합니다.
						</p>
					</div>
					<!-- //가입승인 -->
				</div>
			</div>
			</template>
			<template
				v-else-if="item_cartel.step == 2"
			>
				<div class="subindex_mainbox">
					<div class="container">
						<div class="input_area">
							<h4 class="tit">카르텔 관심사를 선택하세요.</h4>
						</div>
						<ul class="ct_interest">
							<li>
								<div class="bright_radio interest_area">
									<input
										v-model="item_cartel.concern_sphere_code"
										value="CM00400001"
										type="radio" id="music"
									>
									<label for="music">
										<strong>Music</strong>
										<p>음악을 좋아하고 관심을 가지고 있습니다.</p>
									</label>
								</div>
							</li>
							<li>
								<div class="bright_radio interest_area">
									<input
										v-model="item_cartel.concern_sphere_code"
										value="CM00400002"
										type="radio" id="art"
									>
									<label for="art">
										<strong>Art</strong>
										<p>예술, 디자인 등에 관심을 가지고 있습니다.</p>
									</label>
								</div>
							</li>
							<li>
								<div class="bright_radio interest_area">
									<input
										v-model="item_cartel.concern_sphere_code"
										value="CM00400003"
										type="radio" id="fashion"
									>
									<label for="fashion">
										<strong>Fashion</strong>
										<p>패션 디자인, 모델 등에 관심을 가지고 있습니다.</p>
									</label>
								</div>
							</li>
							<li>
								<div class="bright_radio interest_area">
									<input
										v-model="item_cartel.concern_sphere_code"
										value="CM00400004"
										type="radio" id="influence"
									>
									<label for="influence">
										<strong>Influence</strong>
										<p>유튜버, 블로거 등을 운영하는 것에 관심을 가지고 있습니다.</p>
									</label>
								</div>
							</li>
						</ul>


					</div>
				</div>
			</template>

			<button
			></button>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'mafia119'
		, props: ['callBack', 'user']
		, data: function(){
			return {
				program: {
					name: '카르텔 개설'
					, title: ''
					, type: 'cartel_sub'
					, not_footer: true
					, not_header: true
					, is_next: true
					, callBack: {
						name: 'cartel'
						, step: 1
					}
				}
				, item_cartel: {
					step: 1
					, cartl_name: ''
					, cartl_desctn: ''
					, entry_approval_fg: false
					, concern_sphere_code: []
					, user_id: this.user.id
				}
				, cartel_img: ''
			}
		}
		, methods: {

			setFile: function(e){

				this.cartel_img = this.$refs.file.files[0]
				console.log(e)

				const reader = new FileReader()
				let self = this
				reader.onload = function(e){
					self.$set(self.item_cartel, 'img_src', e.target.result)
				}
				reader.readAsDataURL(e.target.files[0])
			}
			, postFile: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_file
						, data: {
							member_number: this.user.member_number
							, file_upload_code: 'CM00700002'
							, appendix_file_div_code: 'CA01000001'
							, appendix_file_name: this.cartel_img
						}
						, multipart: true
						, type: true
					})

					if(result.success){
						this.item_cartel.cartl_img_url = result.data.file_url
						return true
					}else{
						throw result.message
					}

				}catch (e) {
					console.log('postFile error', e)
					this.$bus.$emit('notify', { type: 'error', message: e})
					return false
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCartel: async  function(){
				console.log('postCartel', this.item_cartel)
				if(this.item_cartel.step == 1) {
					if(!this.item_cartel.img_src){
						this.$bus.$emit('notify', { type: 'error', message: '카르텔 이미지를 선택하세요'})
						return false
					}else if (!this.item_cartel.cartl_name || this.item_cartel.cartl_name == '') {
						this.$bus.$emit('notify', { type: 'error', message: '카르텔 이름을 입력하세요'})
						return false
					} else if (!this.item_cartel.cartl_desctn || this.item_cartel.cartl_desctn == '') {
						this.$bus.$emit('notify', { type: 'error', message: '카르텔 설명을 입력하세요'})
						return false
					} else {
						this.item_cartel.step = 2
						return false
					}
				}else{
					if (!this.item_cartel.concern_sphere_code) {
						this.$bus.$emit('notify', { type: 'error', message: '카르텔 관심사를 선택하세요'})
						return false
					}
				}

				if(await this.postFile()){
					try{
						this.$bus.$emit('on', true)
						const result = await this.$request.init({
							method: 'post'
							, url: this.$api_url.api_path.post_cartel
							, data: {
								member_number: this.user.member_number
								, cartl_name: this.item_cartel.cartl_name
								, cartl_desctn: this.item_cartel.cartl_desctn
								, entry_approval_fg: !this.item_cartel.entry_approval_fg ? 'Y' : 'N'
								, concern_sphere_code: this.item_cartel.concern_sphere_code
								, cartl_img_url: this.item_cartel.cartl_img_url
							}
							, name: 'postCartel'
							, type: true
						})

						if(result.success){
							this.$emit('to', { name: 'mafia1211', params: {idx: result.data.id}})
						}else{
							throw result.message
						}

					}catch (e) {
						console.log(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
					}finally {
						this.$bus.$emit('on', false)
					}
				}
			}

			,goBack: function(){
				if(this.item_cartel.step == 1){
					this.$emit('goBack')
				}else{
					this.item_cartel.step = 1
				}
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			if(this.user.cartl_open_possible_fg != 'Y'){
				this.$emit('goBack')
			}
		}
	}
</script>